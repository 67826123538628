<template>
  <div class="text-left">
    <BaseHeader :title="'Discounts'"></BaseHeader>

    <!-- <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading> -->

    <section>
      <div class=" mt-2">
        <!-- <base-select-site
          class="p-2"
          @siteSelected="_getDiscounts"
        ></base-select-site> -->

        <div class="card text-right d-flex align-items-center flex-row w-100 justify-content-between p-3" style>
          <v-text-field
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="my-auto"
          ></v-text-field>
          <v-spacer></v-spacer>
          <button 
            color="primary"
            class="my-auto btn btn-add ml-2"
            size="small"
            @click.stop="crudAction"
            >New</button
          >
        </div>
        <!-- datatable -->

        <v-data-table
          :mobile-breakpoint="0"
          :headers="discountHeaders"
          :items="discounts"
          :search="$store.state.search"
        >
          <template v-slot:[`item.default`]="{ item }">
            <v-chip
              v-if="item.default !== '0'"
              small
              outlined
              color="teal"
              class="text-light"
              >Default</v-chip
            >
          </template>

          <template v-slot:[`item.private`]="{ item }">
            <v-chip v-if="item.private != 0" color="primary" outlined small
              >Private</v-chip
            >
          </template>

          <template v-slot:[`item.recursive`]="{ item }">
            <v-chip v-if="item.recursive != 0" color="primary" outlined small
              >Recursive</v-chip
            >
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              elevation="0"
              @click="
                editMode(item);
                $store.state.dialog = true;
              "
              fab
              x-small
            >
              <v-icon small color="error">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="discountsForm.busy">
          <v-card-title class="text-h5">
            {{ edit ? `Update ${discountsForm.level || ""}` : "New Discount" }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="discountsForm"
              @submit.prevent="createWriterLevel"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Discount Code e.g. WELCOME10"
                      name="code"
                      type="text"
                      v-model="discountsForm.code"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="discountsForm"
                            class="v-messages theme--light error--text"
                            field="code"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Percentage Discount"
                      name="percentage"
                      type="text"
                      v-model="discountsForm.percentage"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="discountsForm"
                            class="v-messages theme--light error--text"
                            field="percentage"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="On orders above"
                      name="on_orders_above"
                      v-model="discountsForm.on_orders_above"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="discountsForm"
                            class="v-messages theme--light error--text"
                            field="on_orders_above"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="py-0">
                    <div class="alert alert-info mb-0 py-1 px-2">
                      <p class="font-weight-bold text-uppercase">Note:</p>
                      <ul>
                        <li>
                          A private discount code cannot be seen on the order
                          form while placing an order.
                        </li>
                        <li>
                          A recursive discount code can be reused many times
                        </li>
                      </ul>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-switch
                      v-model="discountsForm.private"
                      label="Private"
                      color="primary"
                      :value="discountsForm.private"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-switch
                      v-model="discountsForm.recursive"
                      label="Is Recursive"
                      color="primary"
                      :value="discountsForm.recursive"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="4" v-show="edit">
                    <v-switch
                      v-model="discountsForm.status"
                      label="Is Active"
                      color="primary"
                      :value="discountsForm.status"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-btn-toggle class="mt-3">
                  <v-btn
                    :disabled="discountsForm.busy"
                    type="submit"
                    color="primary"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Discounts",
  computed: {
    ...mapState("payment", [
      "discountsForm",
      "loading",
      "discounts",
      "discountHeaders",
    ]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("payment", ["_getDiscounts"]),
    // ...mapActions("clients", ["getClients"]),
    createWriterLevel() {
      this.discountsForm.default = this.discountsForm.default ? true : false;
      this.discountsForm.recursive = this.discountsForm.recursive ? 1 : 0;
      this.discountsForm.order_based = this.discountsForm.order_based ? 1 : 0;
      this.discountsForm.private = this.discountsForm.private ? 1 : 0;
      this.discountsForm.site_id = this.$store.state.site_id;
      console.log("discountsForm", this.discountsForm);
      this.edit
        ? this.discountsForm
            .put(`${this.$baseUrl}/Discounts/Discount/`)
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Discount updated successifully.`,
                style: "success",
              });
              this._getDiscounts();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating discount`,
                style: "danger",
              });
            })
        : this.discountsForm
            .post(`${this.$baseUrl}/Discounts/Discount/`)
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Discount created successifully.`,
                style: "success",
              });
              this._getDiscounts();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating discount`,
                style: "danger",
              });
            });
    },

    editMode(style) {
      this.edit = true;
      this.discountsForm.reset();
      this.$store.state.dialog = true;
      Object.assign(this.discountsForm, { ...style });
      this.discountsForm.default =
        this.discountsForm.default !== "0" ? true : false;
      this.discountsForm.active =
        this.discountsForm.active !== "0" ? true : false;
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.discountsForm.reset();
        this.$store.state.dialog = true;
      } else {
        this.$notify({
          title: "Error",
          text: "Please select a site to proceed",
          style: "danger",
        });
      }
    },
  },
  async mounted() {
    this.$store.state.site_id = this.$route.params.siteid

    if (this.$store.state.site_id) {
      await this._getDiscounts();
    }
  },
};
</script>
